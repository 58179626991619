import { PageWrapper } from "../../utils/pageWrapper";
import { Assets } from "./components/assets";
import { Cover } from "./components/cover";
import { Help } from "./components/help";
import { PhysicalCard } from "./components/physicalCard";
import { Possibilities } from "./components/possibilities";
import { Question } from "./components/question";
import { Relatives } from "./components/relatives";
import { Transfer } from "./components/transfer";
import { UserTurn } from "./components/userTurn";

function Home() {
  return (
    <PageWrapper>
      <main>
        <div className="md:px-14">
          <Cover />
          <Possibilities />
          <Assets />
        </div>
        <div>
          {/* <Transfer /> */}
        </div>
        <div className="md:px-14">
          <PhysicalCard />
        </div>
        <div>
          <Relatives />
        </div>
        <div className="md:px-14">
          <UserTurn />
          <Question />
          <Help />
        </div>
      </main>
    </PageWrapper>
  );
}

export default Home;
