import React from "react";
import { motion } from "framer-motion";
import { t } from "i18next";
import { imagesFont } from "../../../constants/appConstants";
const {appstore, playstore} = imagesFont;

export const Cover: React.FC<any> = ({}) => {
  const goToWebApp = () => {
    window.location.href = "https://web.payqin.com";
  };
  return (
    <section className="flex flex-col pt-10 md:pt-24 lg:pt-40">
      <div className="text-center px-10 md:px-30 2xl:px-30">
        <h1 className="text-2xl md:text-6xl lg:text-8xl font-bold leading-normal">
          {t("index.welcome")}
        </h1>
        <p className="mt-8 text-gray-800 font-extralight md:font-light text-sm md:text-base lg:text-lg">
          {t("index.welcomeSub")}
        </p>
      </div>

      <div className="flex justify-center gap-x-4 mt-8">
          <a href="https://apps.apple.com/fr/app/payqin/id1397872810" target="_blank"><img src={appstore} alt="App Store" className="h-12"/></a>
          <a href="https://play.google.com/store/search?q=payqin&c=apps" target="_blank"><img src={playstore} alt="Google Play" className="h-12"/></a>
      </div>
    </section>
  );
};
